body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  background-image: url("./img/FMK-background-4.jpg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-header-media:before {
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  z-index: 1;
}

.custom-header-content a:hover,
.custom-header-content a:focus {
  text-decoration: none;
}

.custom-header-media img {
  position: relative;
  width: 100%;
  display: block;
}

#header-nav-area {
  /*display: block;
	padding: 8px 30px;
	position: relative;*/
  animation: fade-in 3s;
}

#header-nav-area .wrapper {
  /*padding-left: 50px;
	padding-right: 50px;*/
}

.menu-toggle {
  background-color: transparent;
  border: none;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1;
  position: relative;
}

.menu-toggle.toggled-on,
.menu-toggle.toggled-on:hover,
.menu-toggle.toggled-on:focus {
  background-color: transparent;
}

.menu-btn-section {
  padding-top: 20px;
}

.menu-nav-section {
  padding-bottom: 20px;
}

span.nav-btn-text {
  color: black;
  position: relative;
  font-size: 20px;
  top: -10px;
  padding: 10px;
}

table.nav {
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #afafaf;
  border-spacing: 20px;
  font-size: 22px;
  width: 90%;
  max-width: 200px;
  text-align: center;
  table-layout: fixed;
}

tr.nav {
  padding: 30px;
  color: black;
  height: 40px;
}

ul.nav {
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  list-style-type: none;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  display: table;
  color: white;
  /* Added the following two properties to display border */
  border: 2px solid #afafaf;
}

.nav-bar {
  animation: fade-in 0.5s;
}

li.nav {
  display: inline;
  padding: 0;
  margin: 0;
  display: table-cell;
}

img.kursbild {
  max-width: 70%;
  width: 300px;
  border-radius: 20px;
}

img.kursbild-mindre {
  max-width: 70%;
  width: 200px;
  border-radius: 20px;
}

.rundad {
  border-radius: 20px;
  animation: fade-in 3s;
}

div.inskutt {
  padding-left: 20px;
}

/* For browsers that support 'object-fit' */
@supports (object-fit: cover) {
  .custom-header-media img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    -ms-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
    width: 100%;
  }
}

.custom-header-media {
  /* Specificity prevents .color-dark button overrides */
  font-size: 18px;
  font-size: 1.125rem;
  text-decoration: none;
  position: relative;
  animation: fade-in 3s;
  -webkit-transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  -moz-transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  -o-transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  z-index: 100;
  width: 100%;
}

div.program-heading {
  width: 650px;
  max-width: 85%;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  color: black;
  margin-right: auto;
  border: 1px solid #aaaaaa;
  border-radius: 15px;
  box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.3);
  animation: fade-in 3s;
  -webkit-transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  -moz-transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  -o-transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.3s ease-in-out;
}

div.program-heading:hover {
  opacity: 0.85;
}

.is-visible div.pBody {
  animation: fade-in 1.2s;
}

.fade-in-slow {
  animation: fade-in 5s;
}

.fade-in-fast {
  animation: fade-in 1.2s;
}

div.kompakt {
  max-width: 95%;
  width: 600px;
  text-align: left;
}

div.kompakt-mer {
  max-width: 95%;
  width: 400px;
  text-align: left;
}

.fade-in-mid {
  animation: fade-in 3s;
}

table.links-table {
  max-width: 95%;
  padding: 4px;
}

table.links-table td {
  padding-top: 4px;
  padding-left: 5px;
}
