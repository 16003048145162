.pBody {
  color: black;
  padding-bottom: 20px;
  text-align: left;
  width: 100%;
  max-width: 550px;
}

.entrySec {
  display: block;
  opacity: 0.5;
  transform: translate(0, 50%);
  visibility: hidden;
  transition:
    opacity 300ms ease-out,
    transform 300ms ease-out;
  will-change: opacity, visibility;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
